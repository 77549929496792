<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div class="modal-header">
        <h3>在线聊天</h3>
      </div>
      <div id="chatbox" class="modal-body">
        <div v-for="(item,index) in data" :key="index">
          <div :class="item.isOneself==1?'message-row-right':'message-row-left'">
            <div class="message-content">
              <div :style="item.isOneself==1?'text-align:right;display: flex;flex-direction:row-reverse':''">
                {{ item.chatName }}
                <span class="message-time">
                  {{ item.createTime }}</span>
              </div>
              <div class="message-body">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="modal-msg">
          <textarea v-model="textdata" :placeholder="placeholder" :rows="4" style="margin: 10px;height: 89px;width: 560px;font-size: 15px;letter-spacing: 1.5px;padding: 8px;" @keydown.enter="confirm" />
        </div>
        <div class="modal-footer-btn">
          <button type="button" class="btn-close" @click="closeSelf">关闭</button>
          <button type="button" class="btn-confirm" @click="confirm">发送</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listByChatKey, saveBatch } from '@/api/chat'
import { v4 } from 'uuid'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Chat',
  props: {
    istogglemodal: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeme'],
  data() {
    return {
      data: [],
      froms: {
        id: '',
        isOneself: 0, // 是否自己发送消息
        createTime: '', // 系统时间
        content: '欢迎来到英雄联盟',
        chatName: '客服小二',
        chatKey: ''
      },
      chatKey: '',
      textdata: '',
      placeholder: '主动一点，世界会更大！',
      intervalId: null
    }
  },
  watch: { // 自动触发写入的函数
    istogglemodal(newCode) {
      if (newCode) {
        this.show()
      }
    },
    data: {
      handler(val, oval) {
        if (val) {
          this.$nextTick(() => {
            document.getElementById('chatbox').scrollTop = 999999
          })
        }
      },
      deep: true
    }
  },
  unmounted() {
    // 在页面销毁后，清除计时器
    this.clear()
  },
  mounted() {
    // this.$refs.chatMain.scrollTop = this.$refs.chatMain.scrollHeight
    // // 监听window的resize事件
    // window.onresize = () => {
    //   this.$refs.chatMain.scrollTop = this.$refs.chatMain.scrollHeight
    // }
  },
  methods: {
    closeSelf() {
      this.$emit('closeme')
    },
    show() {
      this.froms.createTime = this.getCurrentTime()
      // 保存聊天唯一标识
      let chatkey = window.localStorage.getItem('chatkey')
      if (chatkey == null) {
        chatkey = v4().replaceAll('-', '')
        window.localStorage.setItem('chatkey', chatkey)
        this.froms.chatKey = chatkey
        this.chatKey = chatkey
        this.saveFirstChat(this.froms)
      } else {
        this.froms.chatKey = chatkey
        this.chatKey = chatkey
        this.getListByChatKey(chatkey)
      }
      this.dataRefreh()
    },
    confirm() { // 发送消息
      if (!this.textdata) {
        alert('不能发送空白信息')
        return
      }
      const msgObj = {
        id: '',
        isOneself: 1, // 是否自己发送消息
        createTime: this.getCurrentTime(), // 系统时间
        content: this.textdata,
        chatName: '匿名用户',
        chatKey: this.chatKey
      }
      const pushData = []
      pushData.push(msgObj)
      saveBatch(pushData).then((res) => {
        this.clear()
        if (res.code === 0) {
          this.dataRefreh()
          this.data.push(msgObj)
          console.log('保存消息成功~~~~~')
          this.placeholder = ''
          this.textdata = ''
        }
        console.log(document.getElementById('chatbox'))
        console.log(document.getElementById('chatbox').scrollHeight)
        console.log(document.getElementById('chatbox').scrollTop)
      })
      document.getElementById('chatbox').scrollTop = 999999
    },
    saveFirstChat(param) { // 保存消息
      const pushData = []
      pushData.push(param)
      saveBatch(pushData).then((res) => {
        this.clear()
        if (res.code === 0) {
          this.data.push(this.froms)
          this.dataRefreh()
          console.log('保存消息成功~~~~~')
        }
      })
    },
    getListByChatKey(param) {
      const params = {
        'chatKey': param
      }
      listByChatKey(params).then((res) => {
        if (res.data.length === 0) {
          this.saveFirstChat(this.froms)
        } else {
          this.data = res.data
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        console.log('刷新' + new Date())
        this.getListByChatKey(this.chatKey)
      }, 5000)
    },
    clear() { // 停止定时器
      clearInterval(this.intervalId) // 清除计时器
      this.intervalId = null // 设置为null
    },
    getCurrentTime() { // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const hh = new Date().getHours()
      const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
    }
  }
})
</script>

<style>
.modal-backdrop {
    z-index: 999;
    position: fixed;
    bottom: 35px;
    right: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal {
    background-color: #fff;
    box-shadow: 2px 2px 20px 1px;
    overflow-x:auto;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    width: 580px;
}
.modal-header {
    border-bottom: 1px solid #eee;
    color: #313131;
    justify-content: space-between;
    padding: 15px;
    display: flex;
}
.modal-footer {
    border-top: 1px solid #eee;
}
.modal-footer-btn {
    justify-content: flex-end;
    padding: 0 15px 15px 15px;
    display: flex;
}
.modal-body {
    position: relative;
    padding: 20px 10px;
    height: 400px;
    background-color: #eee;
    border: 1px solid #c0c0c0;
    overflow-y: scroll;
}
.btn-close, .btn-confirm {
    border-radius: 8px;
    margin-left:16px;
    width:56px;
    height: 36px;
    border:none;
    cursor: pointer;
}
.btn-close {
    color: #313131;
    background-color:transparent;
}
.btn-confirm {
    color: #fff;
    background-color: #2d8cf0;
}

.message-row-left {
  display: flex;
  flex-direction:row;
  margin:5px 0;
}
.message-row-right {
  display: flex;
  flex-direction:row-reverse;
  margin:5px 0;
}

.message-time {
  margin:0 5px;
  font-size:5px;
  color:#959995;
}

.message-body {
  border:1px solid #D9DAD9;
  padding:5px;
  border-radius:3px;
  background-color:#FFF;
}
</style>
