<template>
  <div class="slider-back">
    <div v-show="iScroll" class="totop" @click="toTopFun">
      <img src="@/assets/images/icons/right-top-select.png" alt="totop" />
    </div>
    <div class="service" @click="toggleModal">
      <img src="@/assets/images/icons/service.png" alt="service" />
    </div>
    <!-- 模态框 -->
    <div class="hello">
      <chat v-show="showModal" :istogglemodal="showModal" @closeme="closeme" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import chat from "@/components/Chat";
export default {
  name: "Totop",
  components: {
    chat,
  },
  setup() {
    const scroll = ref(0);
    const iScroll = ref(false);
    const showModal = ref(false);
    function toggleModal() {
      showModal.value = !showModal.value;
    }
    function closeme() {
      showModal.value = !showModal.value;
    }
    const listenerFunction = (fn, wait) => {
      // 节流
      let prev = Date.now();
      return function () {
        const context = this;
        const args = arguments;
        const now = Date.now();
        if (now - prev >= wait) {
          fn.apply(context, args);
          prev = Date.now();
        }
      };
    };
    const changeScroll = () => {
      scroll.value =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll.value > 600) iScroll.value = true;
      else iScroll.value = false;
    };
    const toTopFun = () => {
      const timer = setInterval((_) => {
        if (document.documentElement.scrollTop !== 0) {
          document.documentElement.scrollTop = changeFun(
            document.documentElement.scrollTop
          );
        }
        if (document.documentElement.scrollTop === 0) clearInterval(timer);
      }, 10);
    };
    const changeFun = (num) => {
      return num / 1.2;
    };

    onMounted(() => {
      window.addEventListener("scroll", listenerFunction(changeScroll, 240));
    });

    return {
      scroll,
      iScroll,
      showModal,
      listenerFunction,
      changeScroll,
      toTopFun,
      toggleModal,
      closeme,
    };
  },
};
</script>

<style lang="less" scoped>
.slider-back {
  position: fixed;
  bottom: 60px;
  right: 48px;
  z-index: 999;

  .totop {
    width: 58px;
    height: 58px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.142);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .service {
    width: 58px;
    height: 58px;
    margin-top: 15px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.142);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
